import type { UzoProductDetailFragment } from '#graphql-operations'

export function fetchProduct(productId: string) {
  const state = useStore()

  return reactiveLoad<UzoProductDetailFragment | undefined | null>(
    () => state.value.productDetail?.[productId],
    (product) => {
      (state.value.productDetail ??= {})[productId] = product

      product?.facetValues?.forEach((facetValue) => {
        const fv = state.value.facetValue?.[facetValue.id]
        if (fv)
          Object.assign(fv, facetValue)
        else
          (state.value.facetValue ??= {})[facetValue.id] = facetValue
      })
    },
    () => useGraphqlQuery('productDetail', { id: productId }).then(result => result.data.productDetail),
  )
}

function simpleHash(value: string) {
  let hash = 0
  for (let i = 0; i < value.length; i++) {
    const char = value.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0
  }
  return hash
}

class SeededRandom {
  constructor(private seed: number) {}
  next() {
    const x = Math.sin(this.seed++) * 10000
    return x - Math.floor(x)
  }
}

const MIN_RATING = 1
const MAX_RATING = 5

interface GetRating {
  (value?: string, precision?: number, range?: [number, number] | [number]): number
  MAX_RATING: number
}

const getRating = ((value, precision = 1, range) => {
  if (!value)
    return 0

  const random = new SeededRandom(simpleHash(value))
  const [min = MIN_RATING, max = MAX_RATING] = range
  return +(random.next() * (max - min) + min).toFixed(precision)
}) as GetRating

getRating.MAX_RATING = MAX_RATING
export { getRating }
