<script lang="ts" setup>
const emit = defineEmits(['click'])
function onClick(event: MouseEvent) {
  emit('click', event)
}
</script>

<template>
  <a class="flex justify-center pb-4" @click="onClick">
    <NIcon icon="i-mingcute:up-line" />
  </a>
</template>
