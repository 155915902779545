<script setup lang="ts">
const props = defineProps<{ rating: string | number }>()

const MAX_RATING = 5

const width = computed(() => ((+props.rating / MAX_RATING) * 100) + 0.5 >> 0)
</script>

<template>
  <div class="inline-flex">
    <span class="relative inline-flex">
      <span class="inline-flex">
        <NIcon
          v-for="n in MAX_RATING" :key="n"
          class="text-context"
          icon="i-heroicons:star-solid" n="slate3"
        />
      </span>

      <span class="absolute inline-flex overflow-hidden whitespace-nowrap" :style="{ width: `${width}%` }">
        <NIcon
          v-for="n in MAX_RATING" :key="n"
          class="text-context" icon="i-heroicons:star-solid" n="amber4"
        />
      </span>
    </span>
  </div>
</template>
