<script setup lang="ts">
import type { UzoProductSearchResultFragment, UzoVariantSearchResultFragment } from '#graphql-operations'
import { isProductVariant } from '~/utils/productType'
import { VARIANT_ID_QUERY_PARAM } from '~/constants/query'

const props = defineProps<{
  item?: UzoProductSearchResultFragment | UzoVariantSearchResultFragment
  freeShipping?: boolean
}>()

const emit = defineEmits<{
  (event: 'click'): void
}>()

const { t } = useI18n()
const priceFormatter = usePriceFormatter()

const productId = computed(() => props.item && (isProductVariant(props.item) ? props.item?.productId : props.item?.id))
const ratingScore = computed(() => getRating(productId.value, 1, [4]))
const to = computed(() => {
  const query = (props?.item && isProductVariant(props.item)) ? `${VARIANT_ID_QUERY_PARAM}=${props.item.id}` : ''
  const path = props.item?.slug
    ? `/products/${productId.value}/${props.item.slug}`
    : `/products/${productId.value}`
  return query ? `${path}?${query}` : path
})

const discountPercentage = computed(() => {
  if (!props.item)
    return 0

  const currentPrice = isProductVariant(props.item)
    ? props.item.price
    : props.item.priceRange
      ? 'value' in props.item.priceRange
        ? props.item.priceRange.value
        : props.item.priceRange.min
      : undefined

  const originalPrice = props.item.customProductMappings?.priceBeforeDiscount
  if (originalPrice && currentPrice && originalPrice > currentPrice)
    return Math.round(((originalPrice - currentPrice) / originalPrice) * 100)

  return 0
})

function makeOldPrice(product?: UzoProductSearchResultFragment | UzoVariantSearchResultFragment) {
  const priceBeforeDiscount = product?.customProductMappings?.priceBeforeDiscount

  if (!product || typeof priceBeforeDiscount !== 'number')
    return undefined

  if (isProductVariant(product)) {
    return priceBeforeDiscount
  }
  else {
    if (!product.priceRange)
      return undefined

    if ('value' in product.priceRange) {
      return { value: priceBeforeDiscount }
    }
    else if ('min' in product.priceRange && 'max' in product.priceRange) {
      const maxPrice = product.priceRange.min !== product.priceRange.max
        ? Math.round(product.priceRange.max * (priceBeforeDiscount / product.priceRange.min))
        : priceBeforeDiscount

      return {
        min: priceBeforeDiscount,
        max: maxPrice,
      }
    }
  }

  return undefined
}

const currentPrice = computed(() => {
  if (!props.item)
    return undefined

  if (isProductVariant(props.item))
    return props.item.price
  else
    return props.item.priceRange ? ('value' in props.item.priceRange ? props.item.priceRange.value : props.item.priceRange.min) : undefined
})

const currentOldPrice = computed(() => {
  const oldPrice = makeOldPrice(props.item)
  if (!oldPrice || !props.item)
    return undefined

  if (isProductVariant(props.item)) {
    return typeof oldPrice === 'number' && oldPrice > props.item.price ? oldPrice : undefined
  }
  else {
    if (typeof oldPrice === 'number')
      return oldPrice

    const oldPriceValue = 'value' in oldPrice ? oldPrice.value : oldPrice.min
    const priceValue = props.item.priceRange ? ('value' in props.item.priceRange ? props.item.priceRange.value : props.item.priceRange.min) : undefined

    if (!oldPriceValue || !priceValue || oldPriceValue <= priceValue)
      return undefined

    return oldPriceValue
  }
})
</script>

<template>
  <NCard class="group relative overflow-hidden">
    <!-- Image wrapper -->
    <div class="relative h-auto w-full">
      <slot name="image">
        <NSkeleton
          v-if="!item || !item.featuredAsset?.preview" class="h-full w-full flex-grow object-cover" wave
          shape="rect"
        />
        <NuxtImg
          v-else
          class="aspect-square flex-grow bg-slate-200 object-cover dark:bg-slate-900"
          :src="item.featuredAsset.preview"
          width="250"
          height="250"
          :alt="item.name"
          loading="lazy"
        />
      </slot>

      <slot name="color" />

      <slot name="badge">
        <div class="absolute left-1.5 top-1.5 z1">
          <NTip
            class="flex items-center flex-gap-1 border-green-950 rd-md border-solid bg-green-200 text-[10px] text-green-950 fw600 tracking-wider backdrop-blur"
          >
            <NIcon icon="i-hugeicons:delivery-box-02" class="-mt-0.5" /> {{ t('trustable.productcard.in_stock') }}
          </NTip>
        </div>
      </slot>

      <ContentGuard>
        <div
          class="absolute bottom-0 left-0 right-0 flex items-center justify-between bg-green-600/80 p1 px2 text-xs text-white font-bold"
        >
          <span>
            <NIcon
              class="mt-0.5"
              icon="i-hugeicons:clock-04 "
            /> {{ t('trustable.productcard.shipping_24') }}</span>
        </div>
      </ContentGuard>
    </div>

    <div class="flex flex-1 flex-col px4 py4">
      <h3 class="line-clamp-2 h10 of-hidden text-context" n="sm slate5 dark:slate4">
        <NSkeleton v-if="!item || !item.id || !item.slug || !item.name" class="w-full" wave />
        <NuxtLinkLocale
          v-else
          :to="to"
          @click="emit('click')"
        >
          <span aria-hidden="true" class="absolute inset-0" />
          {{ item.name }}
        </NuxtLinkLocale>
      </h3>
      <slot name="price">
        <NSkeleton v-if="!item" />
        <div v-else class="mt1 flex space-x-1">
          <template v-if="currentOldPrice && currentPrice">
            <ins class="text-context fw600 decoration-none" n="red-700 dark:slate4">
              {{ priceFormatter(currentPrice, item.currency) }}
            </ins>
            <del class="text-context line-through" n="slate5">
              {{ priceFormatter(currentOldPrice, item.currency) }}
            </del>
            <span
              v-if="discountPercentage"
              class="ml3 block border-1 border-red-700 rd-md border-solid px1 py0.5 text-xs text-red-700 fw600 lh-normal tracking-wider backdrop-blur"
            >
              - {{ discountPercentage }}%
            </span>
          </template>
          <template v-else>
            <span class="text-context fw600 decoration-none" n="red-700 dark:slate4">
              {{ priceFormatter(currentPrice, item.currency) }}
            </span>
          </template>
        </div>
      </slot>
      <ContentGuard>
        <div class="flex items-center justify-between pt2">
          <template v-if="freeShipping || freeShipping != null">
            <NSkeleton v-if="!item" class="w-3/4" pulse />
            <span
              v-else
              class="text-context"
              n="11px green-600"
            >
              {{ t('product.free_shipping.label').toUpperCase() }}
            </span>
          </template>
          <slot name="rating">
            <Rating :rating="ratingScore" class="text-xs -mt0.5" />
          </slot>
        </div>
      </ContentGuard>
    </div>
  </NCard>
</template>
