<script setup lang="ts">
import { isDeepEqual } from '@antfu/utils'
import type { UzoSearchInput } from '#graphql-operations'

const props = withDefaults(defineProps<{
  search?: UzoSearchInput
  resetSearchQueryParams?: string[]
  blocking?: boolean
}>(), {
  blocking: true,
})

const emit = defineEmits<{
  (event: 'total', value: number): void
  (event: 'loading', value: boolean): void
}>()

const slots = useSlots()

const isLoading = ref(true)
const {
  take,
  total,
  startPage,
  endPage,
  currentPage,
  canLoadNextPage,
  scrollItemClassName,
  isFetchingData,
  scrollContainerId,
  reachTopHandler,
  fetchNextPage,
} = useListInfiniteScroll({
  gotoPage,
  fetchPageData,
})

const { items, fetchSearch, clearFetchResults } = useFetchSearch({
  take,
  total,
  isFetchingData,
  isLoading,
  resetSearch: resetPage,
  resetSearchQueryParams: props.resetSearchQueryParams,
})

async function fetchPageData(page: number, loadDirection?: 'up' | 'down') {
  return await fetchSearch({
    page,
    loadDirection,
    search: props.search,
  })
}

function gotoPage(page: number) {
  currentPage.value = page
  startPage.value = page
  endPage.value = page
  isFetchingData.value = false
  isLoading.value = true
  total.value = 0

  clearFetchResults()
  fetchSearch({ page, search: props.search })
}

const skeletonCount = take.value

function resetPage() {
  isLoading.value = true
  gotoPage(1)
}

watch(total, () => emit('total', total.value))
watch(isLoading, () => emit('loading', isLoading.value))

watch(() => props.search, (newSearch, oldSearch) => {
  if (newSearch === undefined || oldSearch === undefined) {
    return
  }
  if (!isDeepEqual(newSearch, oldSearch)) {
    resetPage()
  }
}, { deep: true })

if (import.meta.server || props.blocking) {
  await fetchSearch({
    page: startPage.value,
    search: props.search,
  }).then(() => {
    isLoading.value = false
  })
}
else {
  fetchSearch({
    page: startPage.value,
    search: props.search,
  }).then(() => {
    isLoading.value = false
  })
}

// trigger intersection observer
const target = ref(null)
useIntersectionObserver(target, async ([{ isIntersecting }]) => {
  if (isIntersecting && !isFetchingData.value) {
    await fetchNextPage()
  }
})

// Load more button handler
async function loadMore() {
  if (!isFetchingData.value) {
    await fetchNextPage()
  }
}
</script>

<template>
  <div class="flex-grow">
    <LoadPreviousPage
      v-if="startPage > 1 && !isLoading && total > 0"
      @click="reachTopHandler"
    />

    <div
      v-if="total !== 0 && (!isLoading || !isFetchingData)"
      :id="scrollContainerId"
      class="grid grid-cols-2 mt6 gap-x-2 gap-y-4 sm:grid-cols-minmax-12rem"
    >
      <div
        v-for="(entity, index) in items"
        :key="`${entity.id}-${index}`"
        :data-testid="index"
        :class="scrollItemClassName"
      >
        <ProductCard
          :item="entity"
          type="product"
        />
      </div>

      <!-- skeleton on fetching next page -->
      <template v-if="isLoading || isFetchingData">
        <ProductCardSkeleton
          v-for="n in skeletonCount"
          :key="n"
        />
      </template>

      <!-- intersection observer element -->
      <!--      <div v-else ref="target" /> -->
    </div>

    <!-- skeleton on first load -->
    <div
      v-if="total === 0 && (isLoading || isFetchingData)"
      class="grid grid-cols-2 mt6 gap-x-2 gap-y-4 sm:grid-cols-minmax-12rem"
    >
      <ProductCardSkeleton
        v-for="n in skeletonCount"
        :key="n"
      />
    </div>

    <template v-if="total === 0 && (!isLoading || !isFetchingData)">
      <slot v-if="slots['empty-result']" name="empty-result" />
      <div v-else>
        {{ $t('search.no_results_found') }}
      </div>
    </template>

    <!-- Load More button -->
    <div v-if="total > 0 && !isLoading && !isFetchingData && canLoadNextPage" class="mt4 flex justify-center">
      <NButton
        id="load-more"
        class="rounded-full leading-7 shadow-sm ring-1 ring-black/7"
        @click="loadMore"
      >
        {{ $t('general.load_more.label') }}
      </NButton>
    </div>
  </div>
</template>
